import Vue from 'vue'
import VueRouter from 'vue-router'
// import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/direct/:itemId/:token',
        name: 'Direct',
        component: () => import('../views/SingleItemOverallStatus.vue')
    },
    {
        path: '/',
        name: 'Login',
        component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
    },
    {
        path: '/home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
    },
    {
        path: '/overallstatus',
        name: 'OverallStatus',
        component: () => import(/* webpackChunkName: "about" */ '../views/OverallStatus.vue')
    },
]

const router = new VueRouter({
    routes
})

// 设置路由拦截
// 在vue-router的全局钩子中设置拦截
// 每个路由皆会的钩子函数
// to 进入 from 离开 next 传递
/*
router.beforeEach((to, from, next) => {
  let token = localStorage.getItem('token')
  if (to.meta.requireAuth) {
      if (token) {
          next()
      } else {
          next({
              path: '/login',
              query: {
                  redirect: to.fullPath
              }
          })
      }
  } else {
      next()
  }
})
 */

export default router
