import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VScaleScreen from 'v-scale-screen'
import Element from 'element-ui';
import scroll from 'vue-seamless-scroll'

import 'element-ui/lib/theme-chalk/index.css';
// import echarts from 'echarts';
// import * as echarts from 'echarts/lib/echarts';

//  引入 echarts 主模块。
// import 'echarts/lib/echarts'

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
Vue.use(VScaleScreen)
Vue.use(Element);
Vue.use(scroll);
// Vue.use(Element, { size: 'small', zIndex: 3000 });
